@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@300;500&display=swap");
.reveal{
  position: relative;
  transform: translateY(250px);
  opacity: 0;
  transition: 0.8s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}
.privacy-container {
  font-family: "Lato", sans-serif;
}
.logo-img1{
  width: 170px;
}
.privacy-banner1 header-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
.privacy-banner-details{
    padding: 5% 5% 4% 10%;
}
.privacy-banner1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #33357d;
  color: white;
}

.privacy-banner1 h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 70px;
  font-family: 'Lato';
  margin: 0;
}

.privacy-banner1 p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #f4f4f4cc;
}

.privacy-banner-data {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
}

.privacy-banner-data h1 {
  font-size: 4rem;
  font-weight: 700;
}

.privacy-banner-data p {
  font-family: Lato;
  color: #757575;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}
.tc-content-box {
  margin: 20px 0;
}
.tc-content-box h2 {
  font-size: 2.5rem;
}
.email {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 0;
  display: flex;
  gap: 2px;
}
.mail{
display: flex;
color: #000000;
}
u{
  text-decoration-color:black;
  text-underline-offset:5px;
}
.limitation li{
  list-style-type: circle;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-family: 'Lato';
  color: #757575
}
.instruction li{
    list-style-type: auto;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    font-family: 'Lato';
    color: #757575
  }
  .steps li{
    list-style-type: auto;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    font-family: 'Lato';
    color: #757575
  }
.tc-content-box h3{
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 26px;
  font-family: 'Lato';
  color: #000000;
}
.tc-content-box h4{
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 30px; 
  font-family: 'Lato';
  color: #000000;
}
.temp span{
  color: #0095F6;
}
@media (max-width: 430px){
  .privacy-banner1 header-wrapper{
    padding: 40px;
  }
  .tc-content-box h3{
    font-size: 16PX;
    line-height: 20PX;
  }
  .email{
    font-size: 11px;
  }
  .privacy-banner1{
    min-height: auto;
    height: auto;
  }
  .privacy-banner1 h1{
    margin: 0;
    margin-top: 0;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
  .privacy-banner-details{
    padding: 5% 0% 0% 0%;
  }

.privacy-banner-data h1 {
  font-size: 22px;
  font-weight: 700;
  font-family: 'Lato';
}
.privacy-banner-data p{
  font-size: 16px;
  line-height: 22px;
  font-family: 'Lato';
  font-weight: 400;
}
.tc-content-box h2{
  font-size: 20px;
  line-height: 26px;
  font-family: 'Lato';
}
.limitation li{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
.instruction li{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
.steps li{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
}
@media (max-width: 375px){
  .privacy-banner1 h1{
  margin-top: 15px;
  }
}
