@import url('https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Lato:wght@400;700&display=swap');
 
.reveal{
    position: relative;
    transform: translateY(250px);
    opacity: 0;
    transition: 0.8s all ease;
  }
  .reveal.active{
    transform: translateY(0);
    opacity: 1;
  }
.leftContainerWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        padding-top: 7%;
    
}
.logo-img1{
    width: 170px;
  }
.leftContainerTopParagraph p {
    color: #fff;
    text-align: center;
    font-feature-settings: "liga" off;
    align-self: stretch;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Lato';
    line-height: 26px;
    margin: 0;
}
.leftContainerHeader h1 {
    align-self: stretch;
    color: #fff;
    letter-spacing: -1.68px;
    width: 100%;
    font-size: 64px;
    line-height: 70px;
    font-family: 'Lato';
    margin: 0%;
}
.leftContainerBottomParagraph p {
    align-self: stretch;
    color: #EDEDED;
    font-size: 21px;
    line-height: 28px;
    font-family: 'Lato';
    width: 75%;
    margin: 0;
}
.getTheAppNow {
    color: #33357d;
    text-align: center;
    font-feature-settings: "liga" off;
    white-space: nowrap;
    justify-content: center;
    border-radius: 34px;
    border: 1px solid #fff;
    background-color: #fff;
    align-self: start;
    margin-top: 10px;
    padding: 18px 50px;
    font: 600 16px/150% Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
}
.banner3 {
    background-image: url(/public/Images/home-background.svg);
    background-repeat: no-repeat;
    min-height: 700px;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    padding: 5% 10% 0 10%;
} 
 
.fuelTheFire {
    padding-top: 0%;
    padding-bottom: 10%;
}
 
.banner4 {
    background-image: url(/public/Images/banner4.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    height: 347px;
    background-size: cover;
    padding: 5% 10% 5% 10%;
 
}
 
.fuelTheFireWrapper {
    position: relative;
    display: flex;
    width: 1209px;
    max-width: 100%;
    flex-direction: column;
    align-items: start;
    justify-content: center;
 
}
 
.fuelTheFireWrapper h1 {
    color: #fff;
    letter-spacing: -1.35px;
    align-self: stretch;
    font: 700 45px/51px Lato, sans-serif;
    margin: 0;
}
 
.fuelTheFireWrapper p {
    color: #EDEDED;
    align-self: stretch;
    font: 500 24px/30px Lato, sans-serif;
}
 
.joinNow {
    color: #33357d;
    text-align: center;
    font-feature-settings: "liga" off;
    white-space: nowrap;
    justify-content: center;
    border-radius: 34px;
    border: none;
    background-color: #fff;
    padding: 10px 20px;
    font: 400 16px/150% Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
    margin-top: 30px;
}
 
.diveIntoInterestContainer {
    border-radius: 0px 39px 39px 0px;
    background-color: #ebebff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5% 10%;
    gap: 50px;
}
.diveIntoInterest {
    align-items: start;
    display: flex;
    flex-direction: column;
}
.diveIntoInterestHeader h1 {
    color: #1a191e;
    align-self: stretch;
    width: 100%;
    font: 800 46px/52px Lato, sans-serif;
    margin: 0;
}
.diveIntoInterestParagraph p {
    color: #1a191e;
    align-self: stretch;
    width: 100%;
    font: 400 20px/32px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.createPollsContainer {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5% 10%;
    gap: 50px;
}
.createPolls {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

}
.createPollsHeader h1 {
    color: #1a191e;
    align-self: stretch;
    width: 100%;
    font: 800 46px/52px Lato, sans-serif;
    text-align: left;
    margin: 0;
}

.createPollsParagraph p {
    color: #1a191e;
    align-self: stretch;
    width: 100%;
    font: 400 20px/32px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.share {
    align-items: start;
    display: flex;
    flex-direction: column;
}
.join {
    align-items: start;
    display: flex;
    flex-direction: column;
}

  .download-button-features {
    background-color: #FFFFFF;
    border: none;
    border-radius: 50px;
    display: flex;
    padding: 10px 20px;
    gap: 12px;
    width: max-content;
    color: #33357d;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Lato';
    margin-top: 20px;
}
.image-interest img{
    max-width: 100%;
    width: 1500px;
}
.imageContainer3 img{
    max-width: 100%;
    width: 1500px;
}
.image-share img{
    max-width: 100%;
    width: 1500px;
}
.imageContainer1 img {
    max-width: 100%;
    width: 1600px;
}
.image-role img{
    max-width: 100%;
    width: 1500px;
}
.image-join img{
    max-width: 100%;
    width: 1500px;
}
.instruction-container1 {
    padding:10%;
    }
    .instruction-container1 h1 {
      font-size: 36px;
      color: #1A191E;
      font-family: 'Lato';
      line-height: 42px;
      color: #1a191e;
      text-align: center;
      margin: 15px 0 0 0;
    }
    .instruction-container1 p {
      color: #565656;
      margin-top: 10px;
      font: 400 14px Be Vietnam Pro, -apple-system, Roboto, Helvetica,
          sans-serif;
      text-align: center;
    }
    .instruction-wrapper-internal {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 23%;
      }
      .instruction-wrapper-internal img{
        margin-bottom: 20px;
      }
      .instruction-wrapper::after{
        content: '';
        background-image: url(/public/Images/Line.svg);
        height: 2px;
        width: 76%;
        position: absolute;
        top: 20px;
        z-index: -1;
        bottom: 0;
        left: 12%;
      }
      .instruction-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        margin-top: 62px;
      }
      .instruction-wrapper h4 {
        font-family: 'Lato';
        font-size: 20px;
        color: #1A191E;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 10px;
      }
      .instruction-wrapper p {
      
        font-family: 'Lato';
        font-size: 16px;
        color: #565656;
        text-align: center;
      }
@media(max-width:1441px) {

    .leftContainerBottomParagraph p{
        width: 95%;
      }
      
}
 
@media (max-width:1025px) {
    .leftContainerHeader h1{
        font-size: 46px;
        line-height: 52px;
    }
    .leftContainerBottomParagraph p{
        font-size: 20px;
        line-height: 26px;
    }
    .download-button-features{
        margin-top: 0;
    }
    .leftContainerBottomParagraph p{
        width: 90%;
    }
    .leftContainerWrapper{
        padding-top:17%;
    }
}
 
@media (max-width: 769px) {
    .leftContainerWrapper{
        padding-top: 5%;
    }
    .banner3{
        flex-direction: column;
        gap: 70px;
        justify-content: unset;
    }
    .image-role{
        display: flex;
        justify-content: center;

    }
    .imageContainer1{
        display: flex;
        justify-content: center;
    }
    .image-share{
        display: flex;
        justify-content: center;

    }
    .image-interest{
        display: flex;
        justify-content: center;

    }

    .imageContainer3 img{
        width: 500px;
    }
    .instruction-container1 h1{
        text-align: start;
      }
      .instruction-container1 p{
        text-align: start;
      }
      .instruction-wrapper-internal {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 0;
      }
      .instruction-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        gap: 40px;
        margin-top: 40px;
      }
      .instruction-wrapper p{
        text-align: left
        }
        .instruction-wrapper::after{
        display: none;
        }
    .diveIntoInterestContainer {
        flex-direction: column;
        min-height: 0;
        padding: 10%;
        border-radius: 0%;
        gap: 30px;
    }
 
 
    .diveIntoInterestParagraph {
        padding-right: 0;
    }
 
 
    .createPollsContainer {
        flex-direction: column-reverse;
        padding: 10%;
        gap: 30px;
    }
 
    .createPollsParagraph {
        text-align: start;
        padding-left: 0;
    }
 
    .createPolls {
        align-items: flex-start;
        padding: 0;
    }
 
    .createPollsHeader h1 {
        text-align: start;
    }
 
    .getTheAppNow {
        display: none;
    }
 
    .leftContainerBottomParagraph p {
        width: 90%;
        font-size: 16px;
        line-height: 22px;
        
    }
 

 
    .leftContainerHeader h1 {
        font-size: 40px;
        font-family: 'Lato';
        line-height: 46px;
        font-weight: 700;
        margin: 0;
        padding: 0;
    }
    .fuelTheFireWrapper h1 {
        font-size: 30px;
        font-family: 'Lato';
        line-height: 36px;
        font-weight: 700;
    }
 
    .fuelTheFireWrapper p {
        font-size: 16px;
        font-family: 'Lato';
        line-height: 24px;
        font-weight: 400;
    }
    .diveIntoInterestHeader h1 {
        font-size: 30px;
        font-family: 'Lato';
        line-height: 39px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }
 
    .diveIntoInterestParagraph p {
        font-size: 16px;
        font-family: 'Lato';
        line-height: 24px;
        font-weight: 400;
        margin: 0;
        padding: 4% 0%;
    }
  .createPollsHeader h1 {
        font-size: 30px;
        font-family: 'Lato';
        line-height: 39px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }
 
    .createPollsParagraph p {
        font-size: 16px;
        font-family: 'Lato';
        line-height: 24px;
        font-weight: 400;
        margin: 0;
        padding: 4% 0%;
 
    }
 
    .join {
        align-items: flex-start;
    }
 
    .imageContainer img {
        width: 92%;
    }
 
    .createPollsContainer {
        display: flex;
        justify-content: center;
    }
 
    .imageContainer1 img {
        width: 90%;
        height: 90%;
    }
 
    .diveIntoInterestContainer img {
        width: 90%;
    }
 
    .fuelTheFireWrapper {
        padding: 10%;
    }
    .leftContainerHeader h1{
        font-size: 52px;
        line-height: 58px;
    }
 
    .leftContainerHeader {
        padding:0;
    }
    .banner4 {
        background-image: url(/public/Images/banner5.svg);
    }
}
 
@media (max-width: 550px) {
    .leftContainerWrapper{
        padding-top: 10%;
        gap: 30px;
    }
    .image-join img{
        width: 350px;
    }
    .instruction-container1 h1{
        font-size: 36px;
        font-weight: 700;
        font-family: 'Lato';
        line-height: 42px;
        text-align: start;
      }
      .instruction-container1 p{
        text-align: start;
      }
      .instruction-wrapper-internal {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 0;
    }
    .instruction-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        gap: 40px;
        margin-top: 40px;
      }
      .instruction-wrapper p{
        text-align: left
      }
      .instruction-wrapper::after{
        display: none;
      }
  

 
    .diveIntoInterestContainer {
        flex-direction: column;
        min-height: 0;
        padding: 10%;
        border-radius: 0%;
        margin: 0;
        gap: 20px;
    }
 
    .community h1 {
        padding: 0%;
    }
 
    .diveIntoInterestParagraph {
        padding-right: 0;
    }
 
    .createPollsContainer {
        flex-direction: column-reverse;
        padding: 10%;
        gap: 20px;
    }
 
    .createPollsParagraph {
        text-align: start;
        padding-left: 0;
    }
 
    .createPolls {
        align-items: flex-start;
        padding: 0;
    }
 
    .createPollsHeader h1 {
        text-align: start;
    }
 
 
   
 
    .getTheAppNow {
        display: none;
    }
 


    .leftContainerHeader h1 {
        font-size: 40px;
        font-family: 'Lato';
        line-height: 46px;
        font-weight: 700;
        padding-left: 0;
    }
     .fuelTheFireWrapper h1 {
        font-size: 30px;
        font-family: 'Lato';
        line-height: 36px;
        font-weight: 700;
    }
 
    .fuelTheFireWrapper p {
        font-size: 16px;
        font-family: 'Lato';
        line-height: 24px;
        font-weight: 400;
    }
 

 
  
 
    .diveIntoInterestHeader h1 {
        font-size: 30px;
        font-family: 'Lato';
        line-height: 39px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }
 
    .diveIntoInterestParagraph p {
        font-size: 16px;
        font-family: 'Lato';
        line-height: 24px;
        font-weight: 400;
        margin: 0;
        padding: 4% 0%;
    }
 
    .createPollsHeader h1 {
        font-size: 30px;
        font-family: 'Lato';
        line-height: 39px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }
 
    .createPollsParagraph p {
        font-size: 16px;
        font-family: 'Lato';
        line-height: 24px;
        font-weight: 400;
        margin: 0;
        padding: 4% 0%;
 
    }
 
    .join {
        align-items: flex-start;
    }
 
    .imageContainer img {
        width: 92%;
    }
 
    .imageContainer img {
        width: 92%;
    }
 
    .imageContainer3 img {
        width: 400px;
    }
 
    .createPollsContainer {
        min-height: 0;
    }
 
    .imageContainer1 img {
        width: 90%;
        height: 90%;
    }
 
    .diveIntoInterestContainer img {
        width: 90%;
    }
 
    .fuelTheFireWrapper {
        padding: 0%;
    } 
    .banner4 {
        background-image: url(/public/Images/banner5.svg);
    }   
}
@media (max-width: 400px){
    .imageContainer3 img{
        width: 324px;
    }
}
@media (max-width: 320px){
    .leftContainerHeader h1{
        font-size: 30px;
        line-height: 36px;
    }
    .instruction-container1 h1{
        font-size: 28px;
        line-height: 34px;
    }
    .instruction-wrapper h4{
        text-align: start;
    }
    .joinNow{
        margin-top: 0;
    }
  
}