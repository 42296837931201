@import url('https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Lato:wght@400;700;900&display=swap');

.reveal{
  position: relative;
  transform: translateY(250px);
  opacity: 0;
  transition: 0.8s all ease;
}
.reveal.active{
  transform: translateY(0);
  opacity: 1;
}
body{
  margin: 0!important;
  padding: 0;
}
.body1 {
  margin-top: 5px;
  height: 852px;
  width: 100%;
  top: 80px;
}
.instruction-wrapper::after{
  content: '';
  background-image: url(/public/Images/Line.svg);
  height: 2px;
  width: 76%;
  position: absolute;
  top: 20px;
  z-index: -1;
  bottom: 0;
  left: 12%;
}
.banner-content h1 {
  font-size: 64px;
  line-height: 70px;
  color: #FFFFFF;
  font-family: 'Lato';
  margin: 0;
}
.logo-img1{
  width: 170px;
}
.home-banner{
  margin-bottom: 10%;
}
.banner-content h1 span {
  color: #FD6A33;
  font-family: 'Lato';
}
.banner-content p {
  color: #EDEDED;
  font-family: 'Lato';
  font-size: 20px;
 line-height: 26px;
 margin: 1px 0px 0px 0px;
 width: 75%;
}
.download-outline-button1{
  background-color:#FFFFFF;
  border: none;
  border-radius: 50px;
  display: flex;
  padding: 15px 30px;
  gap: 12px;
  cursor: pointer;
}
.download-outline-button1 span{
  color: #33357d;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Lato';
}
.banner-container {
  background-image: url(/public/Images/home-background.svg);
  background-repeat: no-repeat;
  min-height: 700px;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  padding: 5% 10% 0 10%;
}
.banner-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-top: 5%;
}
.banner-conatent-image{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.banner-conatent-image img{
  width: 100%;
  height: max-content;
}
.instruction-container {
padding:0% 10% 10% 10%;
}
.instruction-container h1 {
  font-size: 36px;
  color: #1A191E;
  font-family: 'Lato';
  line-height: 42px;
  color: #1a191e;
  text-align: center;
  margin: 15px 0 0 0;
}
.instruction-container p {
  color: #565656;
  margin-top: 10px;
  font: 400 14px Be Vietnam Pro, -apple-system, Roboto, Helvetica,
      sans-serif;
  text-align: center;
}
.instruction-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  margin-top: 62px;
}
.instruction-wrapper-internal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 23%;
}
.instruction-wrapper-internal img{
  margin-bottom: 20px;
}
.instruction-wrapper h4 {
  font-family: 'Lato';
  font-size: 20px;
  color: #1A191E;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
}
.instruction-wrapper p {

  font-family: 'Lato';
  font-size: 16px;
  color: #565656;
  text-align: center;
}
.one-nation-container {
  padding: 0% 10% 10% 10%;
  display: flex;
}
.features-list-internal {
  padding: 20px;
  border: 2px solid #E8E8E8;
  border-radius: 12px;
  max-width: 215px;

}
.features-list-internal:hover{
  background-color: #dadbff;
  transform: scale(1.02);
  box-shadow: 0px 0px 21px 1px #c3c5e254;
}

.features-list-internal p{
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  font-family: 'Lato';
}
.features-list-internal h3 {
margin: 30px 0 10px;
 font-size: 20px;
 font-family: 'Lato';
 font-weight: 600;
 line-height: 25px;
}
.features {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.features h1 {
  margin-bottom: 65px;
  font-size: 64px;
  font-family: 'Lato';
  font-weight: 500;
  line-height: 70px;
  margin-top: 0;
}
.features-list {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
button.know-more {
  background: #33357d;
  padding: 10px 30px;
  border: none;
  border-radius: 50px;
  color: white;
  font-weight: 500;
  font-size: 2rem;
  margin-top: 40px;
  cursor: pointer;
}
.features-image {
  display: flex;
  align-items: center;
  align-content: center;
}
.help-your-nation-container {
  background: #353BFF1A;
  padding: 4%;
  display: flex;
  align-items: flex-start;
  gap: 10%;
  margin: 0% 6% 6% 6%;
  border-radius: 30px;
}
.growth-list-wrapper-internal {
  display: flex;
  align-items: center;
}
.growth-list-wrapper-internal p {
  margin: 13px;
}
.help-content-wrapper-img {
  display: flex;
  justify-content: center;
  padding-top: 60px;
}
.help-content-wrapper {
  width: 50%;
}

.left-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 0px;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: flex-start;
}
.align a{
  text-decoration: none;
  font-family: 'Lato';
  font-size: 16px;
  color: black;
  font-weight: 500;

}
.service-list-internal:hover{
  background-color: #dadbff;
  transform: scale(1.02);
  box-shadow: 0px 0px 21px 1px #c3c5e254;
}
.help-content-wrapper h1 {
  font-size: 60px;
  font-family: 'Lato';
  line-height: 64px;
  font-weight: 600;
  margin: 0;
}
.help-content-wrapper p{
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  font-family: 'Lato';
}

.help-content-wrapper-img img{
  max-width: 100%;
  width: 550px;
}
@media (max-width:1750px){
  .features-image img{
    width: 100%;
  }
  .features{
    width: 60%;
  }
  .features-list{
    width: auto;
  }
}
@media (max-width: 1441px){
  .banner-content p{
    width: 90%;
  }
  .banner-content h1{
    font-size: 56px;
    line-height: 62px;
  }
  .help-content-wrapper{
    width: 68%;
  }
  .help-your-nation-container{
    gap: 2%;
  }
}
@media (max-width:1331px){
  .features{
    width: 100%;
  }
}
@media (max-width: 1025px) {

.instruction-container{
  padding: 4% 10% 10% 10%;
}
.features {
  width: 100%;
}
.features-list-internal {
  max-width: 215px;
}
.help-content-wrapper h1{
  font-size: 40px;
  line-height: 46px;
}
.help-content-wrapper p{
  font-size: 16px;
}
.banner-content h1{
  margin: 0%;
  font-size: 56px;
  line-height: 62px;
}
.banner-content{
  padding-top: 10%;
}
.features h1{
  font-size: 64px;
  line-height: 70px;
}
.help-content-wrapper-img img{
  width: 110%;
}
.help-content-wrapper{
  width: 100%;
}
.help-your-nation-container{
  gap: 0;
}
.one-nation-container{
  flex-direction: column;
}
.features-image img{
  width: 63%;
}
.features-image{
  justify-content: center;
}
}
@media (max-width: 769px){
  .banner-container{
    flex-direction: column;
    gap: 30px;
  }
 
  .banner-content p{
    
    width: 75%;
  }
  .banner-content{
    padding-top: 5%;
  }
  .one-nation-container {
    display: flex;
    flex-wrap: wrap;
    gap: 82px;
}
.features-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.features-image img{
  width: 90%;
}
.banner-content h1{
  font-size: 56px
}
.banner-content p{
  font-size: 16px;
  line-height: 25px;
}
.cta-button{
  margin-right: 1px;
}
.buttons-wrapper{
  margin-top: 48px;
  gap: 15px;
}
.help-content-wrapper-img img {
  max-width: 100%;
}
.help-your-nation-container{
flex-direction: column;
padding: 50px;
}
.help-content-wrapper {
  width: 100%;
  
}
.help-content-wrapper-img{
  justify-content: flex-end;
  padding: 5% 10%;
}
.instruction-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 40px;
}
.instruction-wrapper-internal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0;
}
.instruction-wrapper p{
text-align: left
}
.instruction-wrapper::after{
display: none;
}
.instruction-container h1{
  text-align: start;
}
.instruction-container p{
  text-align: start;
}
}
@media (max-width: 620px){
  .banner-container{
    min-height: 500px;
  }
  .banner-content h1 span{
    font-size: 50px;
    line-height: 56px;
    font-weight: 600;
    font-family: 'Lato';
  }
  .banner-content h1{
    padding: 0;
  }
  .cta-button p{
    margin-left: 5px;
  }
  .cta-button2 p{
    margin-left: 5px;
  }
  
  .banner-content h1{
    font-size: 38px;
    line-height: 44px;
  }
  .buttons-wrapper{
    flex-direction: column;
    gap: 20px;
  }
  .cta-button{
    padding: 2% 8%;
  }
  .cta-button2{
    padding: 2% 8%;

  }
  .instruction-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 40px;
  }
  .instruction-wrapper-internal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0;
}
.instruction-wrapper p{
  text-align: left
}
.instruction-wrapper::after{
  display: none;
}
.features-list-internal {
  max-width: 100%;
}
button.know-more {
  font-size: 1.25rem;
}

.help-content-wrapper {
  width: 100%;
}
.help-content-wrapper-img {
  padding: 0;
  margin-top: 3%;
}
.help-content-wrapper-img img{
  max-width: 100%;
}

.help-content-wrapper h1{
font-size:30px;
font-weight: 700;
font-family: 'Lato';
line-height: 39px;
}
.help-content-wrapper p{
  font-family:'Lato';
font-size: 16px;
font-weight: 500;
line-height: 22px;
}
.growth-list-wrapper-internal p{
  font-size: 18px;
  font-weight: 500;
  font-family: 500;
  line-height: 24px;
}
.features h1{
font-size:30px;
font-weight: 600;
font-family: 'Lato';
line-height: 39px;
margin-bottom: 50px;
width: 100%;
}
.features-image{
    width: 100%;
    display: flex;
    align-items: stretch;
}
.features-image img{
  width: 100%;
}

.help-your-nation-container{
  margin-bottom: 10%;
  flex-direction: column;
  padding: 30px;
}
.instruction-container h1{
  font-size: 36px;
  font-weight: 700;
  font-family: 'Lato';
  line-height: 42px;
  text-align: start;
}
.one-nation-container{
  gap: 30px;
}
.instruction-container p{
  text-align: start;
}
}
@media (max-width: 376px){
  .banner-content h1{
    font-size: 30px;
    line-height: 36px;
  }
  .banner-content h1 span{
    font-size: 42px;
    line-height: 46px;
  }
  
  
}

