@import url('https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Lato:wght@400;700;900&display=swap');
.footer-container {
  background-color: #000000;
  padding: 4% 10% 4% 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-footer {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.left-footer p {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Lato';
  line-height: 24px;
  color: #FFFFFF;
}
.download-outline-button {
  background-color: #FFFFFF;
  border:none;
  border-radius: 50px;
  display: flex;
  padding: 10px 20px;
  gap: 12px;
  width: max-content;
  cursor: pointer;
}
.download-outline-button span {
  color: #33357d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Lato';
}
.right-footer h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Lato';
  color: #FFFFFF;
}
.left-ul li {
  list-style: none;
}
.left-ul a {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Lato';
  line-height: 24px;
}
.left-ul ul {
  padding: 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin: 0;
}
.right-ul li {
  list-style: none;
}
.right-ul a {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Lato';
  line-height: 24px;
}
.right-ul ul {
  padding: 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin: 0;
}
.footer-navigation {
  display: flex;
  gap: 60px;
}
.logo-img1{
  width: 170px;
}
@media (max-width: 1441px) {
  .left-footer{
    width: 35%;
  }  

}
@media (max-width: 1025px) {
  .left-footer{
    width: 40%;
  } 
}
@media (max-width: 769px) {
  .footer-navigation{
    gap: 25px;
  }
}
@media (max-width: 701px){
}
@media (max-width: 500px) {
  .footer-container{
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }
  .left-footer{
    width: 75%;
  }
  .footer-navigation{
    flex-direction: column;
    gap: 10px;
  }
  .download-outline-button{
    display: none;
  }
  .footer-container{
    padding: 10%;
  }
}
@media (max-width: 376px){
  .left-footer{
    width: 90%;
  }
}
@media (max-width: 321px) {
  .left-footer{
    width: 100%;
  }
}