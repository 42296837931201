@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@300;500&display=swap");

.privacy-container {
  font-family: "Lato", sans-serif;
}
.logo-img1{
  width: 170px;
}
.privacy-banner header-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.privacy-banner-details{
  padding: 5%;
}
.privacy-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #33357d;
  color: white;
  padding: 50px;
}
.privacy-banner h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 70px;
  margin: 0;
}
.privacy-banner p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #f4f4f4cc;
  margin: 0;
}
.privacy-banner-data {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
}
.privacy-banner-data h1 {
  font-size: 4rem;
  font-weight: 700;
}
.privacy-banner-data p {
  font-family: Lato;
  color: #757575;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.tc-content-box {
  margin: 20px 0;
}
.tc-content-box h2 {
  font-size: 2.5rem;
}
.email {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 0;
  display: flex;
  gap: 2px;
}
.mail{
display: flex;
color: #000000;
}
u{
  text-decoration-color:black;
  text-underline-offset:5px;
}
@media (max-width: 430px){
  .email{
    font-size: 12px;
  }
  .privacy-banner{
    min-height: auto;
    height: auto;
    padding: 5%;
  }
  .privacy-banner h1{
    margin: 0;
    margin-top: 15px;
    font-size: 24px;
    line-height: 30px;
  }
  .privacy-banner p{
    margin: 0;

  }
  .privacy-banner-details{
    padding: 5% 0% 0% 0%;
  }
.privacy-banner-data h1 {
  font-size: 22px;
  font-weight: 700;
  font-family: 'Lato';
}
.privacy-banner-data p{
  font-size: 16px;
  line-height: 22px;
  font-family: 'Lato';
  font-weight: 400;
}
.tc-content-box h2{
  font-size: 20px;
  line-height: 26px;
  font-family: 'Lato';
}
}
@media (max-width: 375px){
  .privacy-banner h1{
  margin-top: 15px;
  }
}
