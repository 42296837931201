@import url('https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Lato:wght@400;700;900&display=swap');

.voice-heard-banner {
  background-image: url(/public/Images/voice-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 500px;
  position: relative;
}
.voice-heard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding:70px ;

}

.voice-heard-wrapper h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 70px;
  font-family: 'Lato';
  color: #33357D;
  margin: 0;
}
.voice-heard-wrapper p {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  font-family: 'Lato';
  margin: 0;
}

@media (max-width: 1770px) {
  .voice-heard-banner {
    background-image: url(/public/Images/voice-tab-banner.svg);
  } 
}

@media (max-width: 1025px) {
  .voice-heard-wrapper h1{
    text-align: center;
  }
  .voice-heard-wrapper p{
    text-align: center;
  }
}

@media (max-width: 769px) {
  .voice-heard-banner {
    background-image: url(/public/Images/voice-banner1.png);
    background-size: cover;
  }
  .voice-heard-wrapper{
    padding: 10%;
    justify-content: center;
    align-items: center;
    gap: 30px;

  }
  .voice-heard-banner h1{
    font-size: 30px;
    font-weight: 800;
    line-height: 42px;
    width: 65%;
  }
  .voice-heard-banner p{
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: center

  }
  .bottom-download{
    margin-top: 0;
  }
}
@media (max-width: 450px) {
  .voice-heard-banner {
    background-image: url(/public/Images/voice-banner1.png);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .voice-heard-wrapper{
    padding: 10%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }
  .voice-heard-banner h1{
    font-size: 30px;
    font-weight: 800;
    line-height: 42px;
    width: 65%;
    text-align: left;
  }
  .voice-heard-banner p{
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: start;
  }
  .bottom-download{
    margin-top: 10%;

  }
}
@media (max-width: 376px) {
  .voice-heard-container h1{
    width: 80%;
  }
  .voice-heard-banner p{
    text-align: st;
  }
  .voice-heard-banner h1{
    width: 100%;
  }

} 