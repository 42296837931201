@import url('https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Lato:wght@400;700;900&display=swap');

.support {
    display: flex;
    justify-content: center;
    padding: 0% 0% 5% 0;
}
.support-wrapper{
    width: 750px;
}
.support-wrapper h1 {
    font-size: 40px;
    line-height: 46px;
    font-family: 'Lato';
    font-weight: 700;
    margin-bottom: 0;
}
.support-wrapper h2{
    font-size: 24px;
    font-family: 'Lato';
    line-height: 30px;
    font-weight: 700;
}
.support-wrapper h3{
    font-size: 20px;
    font-weight: 700;
    font-family: 'Lato';
    line-height: 26px;
    margin-bottom: 0;
}
.support-wrapper p{
    font-size: 16px;
    line-height: 26px;
    font-weight:400;
    font-family: 'Lato';
    margin-top: 0;
    color: #65676B;
}
.logo-img1 {
    width: 170px;
}
.get-help{
    display: flex;
    align-items: center;
    gap: 20px;
    background-color:#E7F3FF;
    padding: 0 0 0 20px;
    border-radius: 10px;
}
.get-help-button{
background: #33357d;
  padding: 6px 22px;
  border: none;
  border-radius: 6px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  font-family: 'Lato';
  cursor: pointer;
}
.get-help-option{
    display: flex;
    justify-content: center;
    gap: 30px;
    padding-top: 50px;
}
.internal-option{
    width: 27%;
    background-color: #F0F2F5;
    padding: 5%;
    border-radius: 10px;
    cursor: pointer;
}
.internal-option img{
    display: flex;
}
.internal-option-img{
    display: flex;
    justify-content: center;
}
.internal-option a{
    text-decoration: none;
    display: flex;
    flex-direction: column;
    list-style: none;
    color: black;
}
.internal-option h3 {
    text-align: center;
}
.internal-option p {
    text-align: center;
}
@media (max-width: 769px){
.support{
    padding: 5% 10%;
}
.internal-option{
    width: 30%;
}
.get-help-button{
    width: 104px;
}
.get-help{
    padding: 0 20px;
}
}
@media (max-width: 430px){
    .support-wrapper h1{
        font-size: 24px;
        line-height: 30px;
    }
    .support-wrapper h2{
        font-size: 20px;
        line-height: 26px;
    }
    .support-wrapper h3{
        font-size: 15px;
        line-height: 21px;
    }
    .support-wrapper{
        width: 382px;
    }
    .support-wrapper p {
        font-size: 12px;
        line-height: 16px;
    }
    .get-help-option{
        flex-direction: column;
        align-items: center;
    }
    .internal-option{
        width: 60%;
    }
    .support{
        padding: 5% 10%;

    }
    .internal-option h3{
        text-align: center;
    }
    .internal-option p{
        text-align: center;
    }
    .get-help{
        flex-wrap: wrap;
        padding: 20px;
        gap: 0;
    }
}
@media (max-width: 321px){
    .internal-option{
        width: 70%;
    }

}

