@import url('https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Lato:wght@400;700;900&display=swap');

body{
    margin: 0!important;
    padding: 0;
  }
.main-nav {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 999;
}
html {
    font-size: 62.5%;
}
a {
    text-decoration: none;
    display: flex;
}
li {
    list-style: none;
}
.main-nav {
    width: 100%;
    height: 80px;
    display: grid;
    grid-template-columns:18rem 23rem 4fr 0fr 18rem ;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 7px 14px -2px;
}
.logo {
    display: grid;
    grid-column: 2/3;
    justify-content: start;
    align-items: center;
}
.download {
    display: flex;
    gap: 10px;
}
.menu-link {
    grid-column: 3/6;
}
.menu-link ul {
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin: 0;
}
.hamburger-container {
    grid-column: 4/5;
}
.menu-link ul li {
    font-size: 1.8rem;
    font-weight: 400;
}
.menu-link ul li a {
    text-transform: capitalize;
    color: rgba(0, 0, 3, 0.8);
    transition: 0.5s;
}

.menu-link ul li:hover>a {
    transform-origin: left;
    color: rgba(0, 0, 3, 1);
    text-decoration: none;
    transition: 0.5s;
}
.hamburger-container .hamburger-menu {
    display: none;
}
.logo img {
    width: 150px;
    padding-bottom: 10px;
}
.download-button{
    background-color:#33367D;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    gap:12px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .download-button img{
    width: 18px;
  }
  .download-button span{
    color: #FEFEFE;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Lato';
  }
  @media (max-width: 1441px){
    .main-nav{
        grid-template-columns: 14rem 1fr 4fr 0fr 14rem;

    }
    .download{
        padding-right: 50px;
    }
  }
@media (max-width: 1080px) {
    .main-nav {
        height: 8rem;
        grid-template-columns: 10rem 1fr 4fr 0fr 10rem;
        ;
    }
    .logo,
    .menu-link ul {
        height: 8rem;
        margin: 0;
    }
}
@media (max-width: 998px) {
    .main-nav {
        height: 7rem;
    }
    .menu-link {
        display: none;
    }

    .logo {
        height: 7rem;
    }
    .mobile-menu-link {
        grid-column: 2/4;
        position: relative;
        z-index: 99;
    }
    .download {
        width: 90%;
        padding: 0;
    }   
    .mobile-menu-link {
        padding-top: 10px;
        background-color: white;
        height: 100vh;
        display: grid;
        width: 100%;
        grid-column: 1/6;
        transition: all 2s linear;
        transform-origin: top;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }
    .mobile-menu-link ul {
        height: 32rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
    .mobile-menu-link ul li:first-child {
        transition-delay: 0.2s;
    }
    .mobile-menu-link ul li{
        border-bottom: 1px solid gainsboro;
        width: 90%;
        display: flex;
    }
    .mobile-menu-link ul li a{
        font-size: 16px;
        line-height: 24px;
        font-family: 'Lato';
        text-align: center;
        font-weight: 600;
        padding-bottom: 4%;
    }
    .hamburger-container {
        grid-row: 1/2;
        grid-column: 3/5;
        justify-items: end;
        align-items: center;
        transition: all 2s linear;
    }
    .hamburger-container .hamburger-container-desktop {
        height: 0;
        display: none;
    }
    .hamburger-container {
        height: 7rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }
    .hamburger-container .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }
}
@media (max-width: 798px) {
    .main-nav {
        height: 6rem;
        grid-template-columns: 8rem 2fr 1fr 1fr 7rem;
    }
    .logo {
        height: 6rem;
    }
    .hamburger-container {
        height: 6rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }
    .hamburger-container .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }
    .mobile-menu-link ul{
        padding: 0 50px;
    }

}
@media (max-width: 520px) {
    .main-nav {
        height: 6rem;
        grid-template-columns: 4rem 4fr 1fr 1fr 3rem;
    }
    .mobile-menu-link ul{
        padding: 0 25px;
    }
    .logo {
        height: 6rem;
    }
    .logo img {
        height: 5rem;
        padding: 0;
    }
    .hamburger-container {
        height: 6rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }

    .hamburger-container .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }
}
@media (max-width: 350px){
    .main-nav{
        grid-template-columns: 3rem 4fr 1fr 1fr 2rem;

    }

}